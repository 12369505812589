@use '../variables';

.page-header {
  /* min-width: 1000px; */
  position: fixed;
  top: 0;
  z-index: 200;
  justify-content: space-between;

  /* display: flex; */
  align-items: center;
  width: 100%;
  padding: 23px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  background: white;

  &-project {
    position: absolute;
    top: 8px;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translateX(-50%);

    img {
      width: 100px;
      background: white;
    }
  }

  &__menu {
    position: absolute;
    top: 50%;
    right: 20px;
    cursor: pointer;
    transform: translateY(-50%);
  }
}

.layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all ease-out 0.8s;

  &--open {
    background: black;
    opacity: 0.5;
    visibility: visible;
  }
}

.side-layout {
  position: fixed;
  top: 0;
  right: -105%;
  width: 30%;
  height: 100vh;
  padding: 60px 40px;
  text-align: center;
  visibility: hidden;
  transition: all ease-in-out 1s;

  &--open {
    right: 0;
    z-index: 99;
    background: variables.$brandblue-bright;
    visibility: visible;
    transition: all ease-in-out 0.3s;
  }

  &--close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    img {
      width: 30px;
    }
  }
}
