.login-page {
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  &__title {
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 415px;
    margin: auto;
    padding: 20px;

    img {
      position: absolute;
      left: 0;
    }
  }

  &__okta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.frame-layout__wrapper {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 40px;
}

.frame-layout__particles-container {
  position: absolute;
  inset: 0;
}

.frame-layout__particles {
  width: 100%;
  height: 100%;

  div {
    height: 100%;
  }
}
