.main-container {
  max-width: 1700px;
  height: 100vh;
  margin: auto;
  padding: 0 20px;
  padding-top: 130px;
  scroll-behavior: smooth;

  &--fitContent {
    width: 100%;
    min-width: fit-content;
    height: 100%;
    padding-top: 201px;
    padding-bottom: 20px;
  }

  &__uploader {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-wrapper {
      align-self: flex-end;
    }
  }

  &__action-bar {
    position: fixed;
    top: 93px;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    width: 100;
    width: 100%;
    padding: 37px 20px;
    background: white;

    &__section {
      display: flex;

      .button-wrapper {
        margin-left: 5px;
      }
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 71px;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: white;
    //   -webkit-mask-image: linear-gradient(black, transparent);
    // }
  }
}

.image-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
