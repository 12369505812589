.modal-wrapper,
.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  color: #707070;
}

.modal-background {
  background-color: #00000045;
}
.modal-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow:
    rgb(255 255 255 / 10%) 0 1px 1px 0 inset,
    rgb(50 50 93 / 25%) 0 50px 100px -20px,
    rgb(0 0 0 / 30%) 0 30px 60px 0;
  border-radius: 8px;
  z-index: 999;
  min-width: 500px;
  max-height: 80vh;
  overflow-y: auto;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 10px;
    border-bottom: 1px solid #707070;

    .modal-title {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 1px;
    }
  }
  .modal-content {
    padding: 10px;
  }
  .modal-footer {
    border-top: 1px solid #707070;
    margin: 0 auto;
  }

  .modal-close {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
