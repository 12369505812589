.dropdown {
  position: relative;
  font-family: Arial, sans-serif;
  margin: 0 5px 5px 0;
  color: #707070;
  background-color: transparent;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    border: none;
    font-size: 16px;

    background-color: transparent;
    cursor: pointer;

    &--open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &__value {
    font-size: 16px;
    margin-left: 10px;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
    overflow: auto;
    max-height: 0;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    list-style: none;
    text-align: left;
    opacity: 0;
    transition:
      transform 0.3s ease,
      max-height 0.3s ease-in-out,
      opacity 0.3s ease;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 9999;
    &--open {
      max-height: 400px;
      opacity: 1;
      transform: scaleY(1);
    }

    li {
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
      z-index: 9999;
      position: relative;
      &.small {
        padding: 3px 10px;
      }

      &:hover {
        background-color: #f0f0f0;
      }
      &.disabled {
        color: gray;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}

.dropdown.open .options {
  max-height: 400px;
  opacity: 1;
  transform: scaleY(1);
}

.multi-dropdown-values {
  display: flex;
  flex-direction: row;
  .multi-dropdown-item {
    height: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    padding: 0 10px;
    margin-right: 10px;
    .remove-item {
      margin-left: 5px;
      height: 20px;
      cursor: pointer;
    }
  }
  .clear-all-button {
    height: 20px;
    border: none;
    background: transparent;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #707070;
    cursor: pointer;
    span {
      margin: 0 10px;
    }
    &.disabled {
      color: #c9c9c9;
      cursor: not-allowed;
    }
  }
}
