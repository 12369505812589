.regenerate-modal-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  min-width: 888px;
  color: #707070;
  .max-characters-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .max-characters-select {
      width: 150px;
      margin: 5px 10px 0 10px;
    }
  }
  .select-image-content {
    display: flex;
    flex-direction: column;
    margin: 10px;
    .select-image-title-wrapper {
      display: flex;
    }
    .select-image-items {
      display: flex;
      cursor: pointer;

      .select-image-item {
        display: flex;
        border: 1px solid #c9c9c9;
        border-radius: 6px;
        margin: 10px;
        &.selected {
          border: 2px solid red;
        }
        .select-image-item-image {
          object-fit: contain;
          width: 200px;
          height: 200px;
        }
        .no-image-placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 200px;
          background-color: #f0f0f0;
          color: #999;
          font-size: 16px;
          border-radius: 6px;
          font-family: Arial, sans-serif;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: end;
  }
}
