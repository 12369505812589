@use '../variables' as v;

.btn {
  position: relative;
  min-width: 100px;
  max-width: 200px;
  padding: 5px;
  background-color: #c9c9c9;
  color: white;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    background-color: #f4f4f4;
    color: #c9c9c9;
    pointer-events: none;
  }

  &:active {
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      display: block;
      width: 60%;
      height: 253px;
      border-radius: 50%;
      background-color: v.$brandblue-main;
      transform: translateX(-50%) translateY(-50%) scale(1.1);
    }
  }

  &-login {
    position: relative;
    width: 426px;
    max-width: unset;
    height: 60px;

    img {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 40px;
      color: white;
      transform: translateY(-50%);
    }
  }

  &-loading {
  }
}
