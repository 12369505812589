.pdf {
  position: relative;
  border: 1px solid #707070;
  border-radius: 15px;
  background-color: #fff;
  padding: 5px;
  width: max-content;
  margin: auto;
  min-width: 940px;
  margin-bottom: 30px;
  hr:last-child {
    display: none;
  }
}

.react-pdf__Page:nth-child(2) {
  border-left: 2px dotted;
}

.react-pdf__Document {
  position: relative;
  display: flex;
  justify-content: center;
  // flex-direction: column;
}

.pdf-show-penalty {
  align-self: center;
  align-items: center;
  display: flex;
  height: 200px;
  width: 30px;
  background-color: #f4f4f4;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.pdf-icons-section {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px;
}

.pdf-checkbox {
  // position: absolute;
  top: 20px;
  right: 60px;
  z-index: 2;

  input {
    width: 17px;
    height: 17px;
  }
}

.pdf-medal {
  // position: absolute;
  top: 15px;
  right: 30px;
  z-index: 2;

  img {
    width: 30px;
    height: 30px;
  }
}

.pdf-download,
.download-all {
  // position: absolute;
  top: 15px;
  right: 5px;
  z-index: 2;
  cursor: pointer;

  img {
    width: 25px;
    height: 25px;
  }
}

.download-all {
  position: relative;
  top: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px;
  background: white;
  margin-top: 20px;

  img {
    margin-left: 5px;
  }

  &-short {
    width: 940px;
    margin: auto;
  }
}
