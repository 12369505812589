@use '../variables' as v;

.tooltip {
  position: relative;
  display: inline-block;
  margin-right: 10px;

  &__content {
    position: absolute;
    top: -10px;
    right: 163%;
    z-index: 2;
    width: 0;
    height: 0;
    padding: 10px;
    border-radius: 5px;
    background: v.$brandblue-bright;
    color: #fff;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;

    & > * {
      font-size: 12px;
      line-height: 1.4;
    }
  }

  &::before,
  &::after {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  &--open {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
    }

    .tooltip__content {
      width: 210px;
      height: auto;
      color: black;
      opacity: 1;
      visibility: visible;
    }
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
  }

  &--left::before,
  &--left::after {
    top: 50%;
    right: 100%;
    margin-right: 15px;
    transform: translate(0, -50%);
  }

  &--left::after {
    margin-right: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid v.$brandblue-bright;
  }
}
