:root {
  --text: #222;
}

$bg: white;
$brandblue-main: #202346;
$brandblue-hover: rgb(32 35 70 / 80%);
$brandblue-bright: #f0f0f2;
$blue-bright-1: #69aacd;
$blue-main: #378ebd;
$brandblue-bright-4: #c8c8d1;
$tenant: #800000;
