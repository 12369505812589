@use 'variables';

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 39px;
}

body {
  margin: 0;
  background-color: variables.$bg;
  color: var(--text);
  color: #333;
  font-size: 16px;
  font-family: SourceSerifPro, Helvetica, sans-serif;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.logo {
  width: 150px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background-color: variables.$brandblue-main;
  color: white;
  text-align: right;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

textarea {
  width: 700px;
  padding: 20px;
  border: 2px dashed #555;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  outline: none;

  &::placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    border: 2px solid transparent;
    outline: variables.$brandblue-main solid 2px;
  }
}

.back-icon {
  position: relative;
  left: 0;
  width: 30px;
  cursor: pointer;
}

.cross {
  width: 20px;
  border-radius: 50%;
  background-color: #dee6fd;
  cursor: pointer;
  fill: #8694d2;
}

.arrow {
  width: 0;
  height: 0;
  margin-left: 5px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.arrow.down {
  border-top: 6px solid #707070;
}

.arrow.up {
  border-bottom: 6px solid #707070;
}

.label {
  width: 100%;
  min-width: 70px;
  max-width: 80px;
  padding: 2px 5px;
  border-radius: 8px;
  color: white;
  text-align: center;

  &--primary {
    background: variables.$blue-main;
  }

  &--secondary {
    background: variables.$brandblue-bright-4;
  }

  &--news {
    background: variables.$tenant;
  }

  &--error {
    background: #ff3333;
  }
}

input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  height: 30px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #707ea0;
  margin-right: 5px;
}

.react-datepicker {
  font-family: 'IBM Plex Sans', sans-serif;
  border-radius: 16px;
  .react-datepicker__header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
