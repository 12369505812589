@use '../variables' as v;

.drop-container {
  width: 100%;
  max-width: 500px;
  min-height: 435px;
  margin: auto;
  margin-bottom: 30px;
  padding: 0 35px;
  border: 1px solid #707070;
  border-radius: 16px;
  background-color: #fff;
  box-shadow:
    rgb(255 255 255 / 10%) 0 1px 1px 0 inset,
    rgb(50 50 93 / 25%) 0 50px 100px -20px,
    rgb(0 0 0 / 30%) 0 30px 60px 0;
  text-align: center;

  &__header-section {
    padding: 25px 0;
    color: #707070;
  }
}

.drop-section {
  position: relative;
  min-height: 250px;
  margin: 5px 0 35px;
  border: 1px dashed #707070;
  border-radius: 12px;
  background-image: linear-gradient(180deg, white, #f1f6ff);

  div.col:first-child {
    width: 200px;
    margin: auto;
    opacity: 1;
    visibility: visible;
    transition-duration: 0.2s;
    transform: scale(1);
  }

  div.col:last-child {
    position: absolute;
    inset: 0;
    width: 200px;
    height: 55px;
    margin: auto;
    color: #c0cae1;
    font-weight: 700;
    font-size: 40px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-duration: 0.2s;
    transform: scale(0.6);
  }

  &.drag-over-effect {
    div.col:first-child {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: scale(1.1);
    }

    div.col:last-child {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  .cloud-icon {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  span,
  button {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    color: #707070;
  }

  button {
    margin-top: 20px;
    padding: 7px 20px;
    border: none;
    background-color: #c9c9c9;
    border-radius: 6px;
    color: white;
    box-shadow:
      rgb(50 50 93 / 25%) 0 13px 27px -5px,
      rgb(0 0 0 / 30%) 0 8px 16px -8px;
    outline: none;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.list-section {
  padding-bottom: 20px;
  text-align: left;

  .list-title {
    color: #707070;
    font-size: 0.95rem;
  }

  li {
    display: flex;
    margin: 15px 0;
    padding-top: 4px;
    padding-bottom: 2px;
    border-radius: 8px;
    transition-duration: 0.2s;

    &:hover {
      box-shadow:
        #e3eaf9 0 0 4px 0,
        #e3eaf9 0 12px 16px 0;
    }

    .col {
      flex: 0.1;

      img {
        width: 50px;
      }
    }

    .col:nth-child(1) {
      flex: 0.15;
      text-align: center;
    }

    .col:nth-child(2) {
      flex: 0.75;
      align-self: center;
      max-width: 340px;
      padding: 8px 10px;
      color: #3e4046;
      font-size: 0.9rem;
      text-align: left;

      .name {
        display: inline-block;
        overflow: hidden;
        max-width: 240px;
        margin-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .file-name {
        display: flex;
        align-items: center;

        span {
          float: right;
          color: #707ea0;
        }
      }
    }

    .file-progress {
      width: 100%;
      height: 5px;
      margin-top: 8px;
      border-radius: 8px;
      background-color: #dee6fd;

      span {
        display: block;
        width: 0%;
        height: 100%;
        border-radius: 8px;
        background-image: linear-gradient(120deg, #6b99fd, #9385ff);
        transition-duration: 0.4s;
      }
    }

    .col .file-size {
      margin-top: 3px;
      color: #707ea0;
      font-size: 0.75rem;
    }

    .col .cross,
    .col .tick {
      position: relative;
      top: 50%;
      left: 50%;
      width: 20px;
      border-radius: 50%;
      background-color: #dee6fd;
      cursor: pointer;
      fill: #8694d2;
      transform: translate(-50%, -50%);
    }
  }
}
