.custom-tooltip {
  cursor: help;
  margin-left: 5px;
  display: inline-block;

  span {
    display: none;
    position: relative;
  }
  &:hover {
    position: relative;
  }

  &:hover span {
    background-color: #ffffff;
    color: #707070;
    box-shadow:
      rgb(255 255 255 / 10%) 0 1px 1px 0 inset,
      rgb(50 50 93 / 25%) 0 50px 100px -20px,
      rgb(0 0 0 / 30%) 0 30px 60px 0;
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 5px 10px 5px 5px;
    display: block;
    z-index: 100;
    top: -20px;
    left: 20px;
    position: absolute;
    font-size: 14px;
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    min-width: 200px;
  }
}
