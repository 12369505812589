.articles-planning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 15px;
  background-color: #fff;
  color: #707070;
  margin-top: 50px;
  box-shadow:
    rgb(255 255 255 / 10%) 0 1px 1px 0 inset,
    rgb(50 50 93 / 25%) 0 50px 100px -20px,
    rgb(0 0 0 / 30%) 0 30px 60px 0;

  .header-content {
    margin: 20px 0;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    .header-title {
      font-size: 28px;
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      margin-top: 23px;
      position: relative;
      z-index: 101;
      .custom-calendar-input {
        font-size: 16px;
        display: flex;
        align-items: center;
        border: none;
        color: #707070;
        background-color: transparent;
        margin-right: 30px;
        .calendar-value {
          margin-left: 10px;
        }
      }
    }
  }
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;

    .elements-table {
      width: 100%;
      .flexTable {
        display: flex;
        flex-direction: column;
        transition:
          transform 0.3s ease,
          height 0.2s ease,
          opacity 0.3s ease;
        transform: scaleY(0);
        transform-origin: top;
        height: 0;
        &.open-table {
          height: 100%;
          opacity: 1;
          transform: scaleY(1);
        }
      }

      .flexTable-row {
        display: flex;
        min-height: 50px;
        &:nth-child(2n + 1) {
          background: #f4f4f4;
        }
      }

      .flexTable-header {
        display: flex;
        background-color: #c9c9c9;
        min-height: 50px;
        color: #ffffff;
      }

      .flexTable-cell {
        flex: 3;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        word-break: break-word;
        font-size: 16px;
        width: 240px;
        margin-left: 10px;
        a {
          color: #707070;
          text-decoration: underline;
        }
        &.small {
          flex: 2;
        }
        &.slug {
          flex: 8;
        }
        &.graph-header {
          justify-content: space-between;
          margin-right: 5px;
          cursor: pointer;
        }
        &.closed {
          flex: 1;
          justify-content: flex-end;
          svg {
            margin-left: 5px;
          }
        }
      }
      .graphs {
        display: flex;
        justify-content: space-between;
        .graphs-input-wrapper {
          align-items: center;
          display: flex;
          align-items: flex-start;
          .graph-label {
            font-size: 18px;
          }
          .graph-input {
            width: 60px;
            height: 23px;
            border: 1px solid #c9c9c9;
            border-radius: 6px;
            opacity: 1;
            color: #707070;
            font-size: 18px;
            margin-left: 5px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .check-graphs-values {
        color: #79c65b;
        margin-right: 20px;
        height: 20px;
      }
      .elements-status-color {
        height: 15px;
        width: 100%;
        max-width: 15px;
        border-radius: 50%;
        display: inline-block;
        background-color: #fff;
        margin-right: 5px;
      }
      .flex3 {
        flex: 3;
      }
      .flex2 {
        flex: 2;
      }
      .element {
        display: flex;
        flex-direction: column;
        margin: 30px;
        position: relative;
        .page-planing {
          display: flex;
          flex-direction: column;
          width: 100%;
          .page-planing-header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .item-name {
              font-size: 20px;
              color: #694c9f;
              margin-left: 10px;
            }
            .button-generate-wrapper {
              margin-left: 25px;
              display: flex;
              align-items: center;
              .btn-generate {
                display: flex;
                align-items: center;
                background-color: #c9c9c9;
                border-radius: 6px;
                border: none;
                font-size: 12px;
                color: #ffffff;
                padding: 2px 5px;
                cursor: pointer;
                p {
                  margin-left: 5px;
                }
                &:disabled {
                  color: #c9c9c9;
                  background-color: #f4f4f4;
                  pointer-events: none;
                }
              }
            }
          }
        }
        .element-info {
          display: flex;
          margin-bottom: 5px;
          p {
            color: black;
            margin-left: 5px;
          }
        }

        .publications {
          margin: 10px 30px;
        }
      }
    }
  }
}
